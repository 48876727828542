import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';
import { commonHeaderColor } from '../../util';


const Page = ({ data }) => (
  <>
    <SEO
      title="Find a Point of Sale fast and simple"
      description="Anyone can access this secure payment method by going to their local convenience store, supermarket, gas station, or other trusted Neosurf retailers."
      alternateLanguagePages={[
        {
          path: 'buy-neosurf/point-of-sale',
          language: 'en'
        },
        {
          path: 'buy-neosurf/point-of-sale',
          language: 'fr'
        },
        {
          path: 'buy-neosurf/point-of-sale',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://bit.ly/2AlU6Ch">
            <Img fluid={data.myneosurf.childImageSharp.fluid} alt="MyNeoSurf Logo" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">Find a Point of Sale</h1>
            <p className="textColorDark">Locate Your Neosurf Point Of Sale With Ease!</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <p className="textColorDark">Are you looking for a Neosurf voucher that you prefer to buy in person? Finding a point of sale is fast and simple, as there are thousands of locations where you can get Neosurf vouchers. Anyone can access this secure payment method by going to their local convenience store, supermarket, gas station, or other trusted Neosurf retailers.</p>
      <p className="textColorDark">The first step to finding a point of sale is to use the online locator tool available on the Neosurf website. This tool allows you to search for authorized sellers by entering your city, postal code, or current location. It only takes a few seconds to get a list of nearby vendors where you can get Neosruf vouchers. The locator tool also provides additional details, such as store hours and contact information, which makes getting the vouchers even easier.</p>
      <p className="textColorDark">For those who travel often and prefer physical purchases, having access to a Neosurf point of sale in your area is incredibly convenient. Because of how widespread Neosurf is, there is a high chance that you can find a Neosurf retail nearby, whether you are at home or exploring a new city. To get a voucher, all you have to do is walk into a store, ask for a Neosurf voucher with the preferred amount, and complete your purchase with cash or card.</p>
      <p className="textColorDark">There is a big advantage to buying in person for those who value privacy and security. With Neosurf, there is no need to provide personal information or financial details when making a purchase. This makes it an ideal option for those who have some concerns about online privacy.</p>
      <p className="textColorDark">After you purchase a Neosurf voucher, you will receive a printed receipt with a unique code. You will use this code to make online payments to those who accept the Neosurf voucher. Using the code is very easy, as all you have to do is enter it at checkout after you select Neosurf as the payment option, and the transaction will be processed instantly. Whether you are topping up your gaming account, shopping online, or subscribing to a service, Neosurf makes this incredibly easy and, more importantly, secure.</p>
      <p className="textColorDark">Neosurf points of sale are always expanding, so you can always rely on finding a seller near your location. By choosing a trusted physical vendor, you can enjoy the confidence that comes with using a globally recognized payment method. Ready to buy a voucher? Use the Neosurf store locator to find the closest point of sale and start enjoying the incredible benefits that come with Neosurf!</p>
    </ContentRow>
  </>
)

export default Page;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
